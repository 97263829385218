<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
        total: number;
        current: number;
        text?: boolean;
    }>(),
    {
        text: true,
    },
);

const progress = computed(() => {
    return Math.min(100, (props.current / props.total) * 100);
});
</script>

<template>
    <div
        v-if="total > 1"
        class="w-full justify-center py-3 flex sm:max-w-[350px] mx-auto"
    >
        <nav
            aria-label="Pagination"
            class="w-full"
        >
            <div
                v-if="text"
                class="pb-3 px-5 justify-center flex"
            >
                <span class="font-bold">
                    {{ current }}
                </span>
                <span> &nbsp;{{ $t('of') }}&nbsp; </span>
                <span class="font-bold">
                    {{ total }}
                </span>
                <span> &nbsp;{{ $t('viewed') }} </span>
            </div>

            <div class="w-full bg-gray-100 rounded-full h-2 min-w-[200px]">
                <div
                    class="h-2 rounded-full bg-gray-300"
                    :style="{ width: progress + '%' }"
                />
            </div>
        </nav>
    </div>
</template>
